.contact { margin-bottom: 2rem;}
.contact h1 {margin: 0}
.dugme {transition: all .4s ease; padding: .5rem 1.3rem; color: #fff; background-color: var(--crna-boja); border-radius: 28px; border: 0;}
.dugme:hover {background-color: var(--zuta-boja); color: var(--crna-boja)}
.dugme:disabled {opacity: .3;}

.sendSuccess { background-color: var(--svetlo-zelena-boja); color: var(--tamno-zelena-boja);}
.sendSuccessIcon {color: var(--tamno-zelena-boja);}
.sendError {background-color: var(--svetlo-crvena-boja); color: var(--tamno-crvena-boja);}
.sendErrorIcon {color: var(--tamno-crvena-boja);}
.contact h2  {text-transform: none; font-size: 2rem; letter-spacing: 0;}