
.ituCertBck {
  width: 100%;
  padding: 120px 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  font-family: "Raleway", sans-serif;
}
.yellowDesc {
  color: var(--zuta-boja);
}
.itucertlink, .itucertlink:visited {color: #fff}