:root {
  --zuta-boja: #f1c30f;
  --crna-boja: #383838;
  --svetlo-siva-boja: #eee;
  --tamno-zelena-boja: #339833;
  --svetlo-zelena-boja: #ddffdd;
  --tamno-crvena-boja: rgb(156, 7, 7);
  --svetlo-crvena-boja: rgb(255, 227, 227);
}

h4 {
  font-weight: 600;
}

/* Forma */
.form-control {
  border: 2px solid #cbcbcb;
  transition: border 0.6s;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border: 2px solid var(--crna-boja);
  outline: 0;
  box-shadow: none;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Open Sans" sans-serif;
  position: relative;
}

a,
a:visited {
  text-decoration: none;
  color: var(--crna-boja);
  transition: color 0.3s;
}

a:hover,
a:active,
a:focus {
  color: var(--zuta-boja) !important;
}
section {
  padding: 54px 0;
}
.navbar {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  background-color: #fff;
  transition: all 0.3s ease;
}

.navbar-nav {
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  text-transform: uppercase;
  font-size: 1.3rem;
  color: var(--crna-boja);
  font-weight: 600;
  padding: 0.5rem 1rem !important;
}
#mainLogo {
  margin: 0.5rem 4rem;
  width: 110px;
}

/* Glavne navigacione stavke */
#pocetna,
#video,
#karakteristike,
#galerija,
#kontakt,
#montaza {
  scroll-margin-top: 150px;
}
#kontakt {
  padding-bottom: 40px;
}

h1 {
  font-size: 42px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: -2px;
}
h2 {
  font-size: 36px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: -1px;
}
.stay-top {
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}
.tinyHeader {
  box-shadow: 0 8px 6px -9px #999;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px);
}
.tinyHeader #mainLogo {
  width: 60px;
  transition: all 0.3s ease;
}
.navbar-toggler {
  background-color: var(--zuta-boja);
  border: none;
  color: #fff;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 6px;
  background-color: var(--zuta-boja);
  background-size: 70%;
}
.carousel-item img {
  width: 100%;
}

.fadeAnim-enter {
  opacity: 0;
}
.fadeAnim-enter-active {
  opacity: 1;
  transition: opacity 800ms;
}
.fadeAnim-exit {
  opacity: 1;
}
.fadeAnim-exit-active {
  opacity: 0;
  transition: opacity 800ms;
}

@media (max-width: 992px) {
  #mainLogo {
    width: 60px;
    margin: 0.5rem;
  }
}

@media (max-width: 768px) {
  #mainLogo {
    width: 70px;
  }
  .navbar {
    margin-top: 0;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  section {
    padding: 40px 0;
  }
  #pocetna,
  #video,
  #karakteristike,
  #galerija,
  #kontakt,
  #montaza {
    scroll-margin-top: 380px;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 576px) {
  h4 {
    font-size: 1rem;
  }
  #karakteristike {
    text-align: center;
  }
}
