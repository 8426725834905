.yellowLine {
  color: var(--zuta-boja);
  width: 45px;
  height: 3px !important;
  border-top: 3px solid var(--zuta-boja);
  opacity: 1 !important;
}
.desc {font-size: 1rem;}
svg {transition: translate .5s ease;}



@media (max-width: 768px) {
  .fshort {text-align: center}
  .yellowLine {margin: 1rem auto;}
  .desc {font-size: 1rem;}
}